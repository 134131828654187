import React, { useRef, useState, useEffect, useCallback } from "react";
import { useGetGlobalNotificationsQuery, useGetNotificationsQuery, useUpdateNotificationStatusMutation } from "../../../services/userApi";

import "./FlashNews.css";
import {
  IoStatsChart,
  IoPeople,
  IoStar,
  IoRocket,
  IoClose,
} from "react-icons/io5";

const FlashNews = () => {
  const carouselRef = useRef(null);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [visible, setVisible] = useState(true);
  const indexRef = useRef(currentNewsIndex);
  indexRef.current = currentNewsIndex;

  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();
  const { data: notifications, isLoading, isError } = useGetGlobalNotificationsQuery();

  const flashNewsItems = notifications?.data || [];

  console.log("FlashNews items:", flashNewsItems);

  // Auto-scroll logic
  useEffect(() => {
    if (!visible || flashNewsItems.length <= 1) return;

    const interval = setInterval(() => {
      const nextIndex = (currentNewsIndex + 1) % flashNewsItems.length;
      setCurrentNewsIndex(nextIndex);
      if (carouselRef.current) {
        const scrollAmount = carouselRef.current.clientWidth * nextIndex;
        carouselRef.current.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentNewsIndex, flashNewsItems, visible]);

  const handleCloseNews = () => {
    setVisible(false);
  };

  const handleDismissNews = async (id) => {
    try {
      await updateNotificationStatus({ id }).unwrap();
    } catch (err) {
      console.error("Failed to dismiss notification", err);
    }
  };


  const onScroll = useCallback(
    (e) => {
      if (!carouselRef.current) return;

      const scrollLeft = e.target.scrollLeft;
      const slideWidth = carouselRef.current.clientWidth;
      const newIndex = Math.round(scrollLeft / slideWidth);

      if (newIndex !== currentNewsIndex) {
        setCurrentNewsIndex(newIndex);
      }
    },
    [currentNewsIndex]
  );

  const getIcon = (iconName) => {
    switch (iconName) {
      case "stats-chart":
        return <IoStatsChart />;
      case "people":
        return <IoPeople />;
      case "star":
        return <IoStar />;
      case "rocket":
        return <IoRocket />;
      default:
        return <IoStatsChart />;
    }
  };

  if (isLoading || isError || !visible || flashNewsItems.length === 0) {
    return null;
  }

  return (
    <div className="flash-news-wrapper">
      <div className="carousel-container">
        <div className="carousel" ref={carouselRef} onScroll={onScroll}>
          {flashNewsItems.map((item) => (
            <div className="banner-container" key={item.id}>
              <div className="flash-news-container">
                <div className="left-section">
                  <div className="flash-news-icon-container">
                    {getIcon(item.icon?.name)}
                  </div>

                  <div className="live-badge">
                    <span className="live-text">LIVE</span>
                    <div className="pulse-dot" />
                  </div>
                </div>

                <div className="flash-news-text-container">
                  <div className="flash-news-text">
                    {item.notificationContent.replace(/\b(you(?:'ve)?)\b/gi, item.fullName)}
                  </div>

                </div>

                {/* Close button per notification */}
                <button
                  className="flash-news-close-button"
                  onClick={() => handleDismissNews(item._id)}
                >
                  <div className="close-button-circle">
                    <IoClose size={10} />
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="dots-container">
          {flashNewsItems.map((_, index) => (
            <div
              key={index}
              className={`dot ${currentNewsIndex === index ? "active-dot" : "inactive-dot"}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlashNews;
