import React from "react";
import "./SliderDots.css";

const SliderDots = ({ slides, currentIndex }) => {
  return (
    <div className="slider-dots">
      {slides.map((_, index) => (
        <div
          key={index}
          className={`dot ${currentIndex === index ? "active" : ""}`}
        />
      ))}
    </div>
  );
};

export default SliderDots;
