import React from "react";
import "./LoginPopup.css";
import { IoClose } from "react-icons/io5";

const LoginPopUp = ({
  modal,
  setModal,
  imageUrl,
  link,
  showPopupFirstTime,
  setShowPopupFirstTime,
}) => {
  if (!modal || !showPopupFirstTime) return null;

  const handleClose = () => {
    setModal(false);
    setShowPopupFirstTime(false);
  };

  return (
    showPopupFirstTime && (
      <div className="login-popup-modal-overlay">
        <div className="login-popup-modal-container">
          <div className="login-popup-close-button" onClick={handleClose}>
            <IoClose size={25} />
          </div>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="login-popup-modal-link"
          >
            <div className="login-popup-modal-content">
              <div className="login-popup-scroll-container">
                <img
                  src={imageUrl}
                  alt="Popup content"
                  className="login-popup-popup-image"
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    )
  );
};

export default LoginPopUp;
