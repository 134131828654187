import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";


const SocialMediaCreateAccountModal = ({ isOpen, handleClose, platform, onConnect }) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          maxWidth: "430px", 
          bgcolor: "white",
          boxShadow: 24,
          p: 4,
          borderTopLeftRadius: "20px", 
          borderTopRightRadius: "20px",
          textAlign: "center",
          pb: 5, 
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontFamily: "'Poppins', sans-serif", 
        }}
      >
        {/* Top Border Indicator (Minimal Line) */}
        <Box
          sx={{
            width: "70px",
            height: "4px",
            backgroundColor: "#3871ff",
            margin: "0 auto 10px", 
            borderRadius: "5px",
            marginBottom: "30px",
          }}
        ></Box>

        {/* Title */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            letterSpacing: "1px",
            mb: 2, 
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Fast Task Approval
        </Typography>

        {/* Description */}
        <Typography
          sx={{
            fontSize: "14px",
            color: "#444",
            mb: 6,
            lineHeight: "1.5",
            px: 4, 
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          You have not connected your {platform || "social media"} account.
          Connect your {platform || "social media"} account to get fast task approval.
        </Typography>

        {/* Connect Button (Exactly like the image) */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3871ff", 
            color: "white",
            width: "80%",
            maxWidth: "450px", 
            padding: "12px",              
            fontWeight: "600",
            fontSize: "15px",
            borderRadius: "20px",  
            textTransform: "uppercase",
            transition: "0.2s ease-in-out",
            fontFamily: "'Poppins', sans-serif",
            marginTop: "10px", 
            "&:hover": {
              backgroundColor: "#3871ff",
              transform: "scale(1.01)", 
            },
          }}
          onClick={onConnect}
        >
          CONNECT {platform?.toUpperCase()}
        </Button>

      </Box>
    </Modal>
  );
};

export default SocialMediaCreateAccountModal;
