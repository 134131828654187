import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { IoClose, IoChevronDown } from "react-icons/io5";
import Pending from "../../../../../components/Pending/Pending";
import { Notification } from "../../../../../components/ToastNotification/index";
import {
  useUpdateProfileMutation,
  useHideEmailMobileWhatsAppMutation,
} from "../../../../../services/userApi";
import { Country } from "country-state-city";
import "./WhatsAppModal.css";
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  getCountryCallingCode,
} from "libphonenumber-js";
import Switch from "@mui/material/Switch";

const WhatsAppModal = ({ modal, setModal, data, title = "Edit WhatsApp" }) => {
  const [user, setUser] = useState({
    whatsapp: data?.whatsapp || "",
  });
  const [isFocus, setIsFocus] = useState(false);
  const [showCountryPicker, setShowCountryPicker] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [selectedFlag, setSelectedFlag] = useState("🇮🇳");
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const [isValid, setIsValid] = useState(true);
  const [isWhatsAppHidden, setIsWhatsAppHidden] = useState(
    data?.isWhatsAppHidden ?? false
  );
  const [hideWhatsAppMutation] = useHideEmailMobileWhatsAppMutation();

  const [updateProfile, { data: updateData, isLoading, error }] =
    useUpdateProfileMutation();

  const handleSubmit = async () => {
    try {
      if (!isValid) {
        Notification("Please enter a valid WhatsApp number", "error");
        return;
      }

      const phoneNumber = `${countryCode}${user.whatsapp}`;
      const parsedNumber = parsePhoneNumber(phoneNumber);

      const newObj = {
        whatsapp: parsedNumber.format("E.164"),
      };
      await updateProfile(newObj);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setUser({ whatsapp: data?.whatsapp || "" });
    if (data && typeof data.isWhatsAppHidden !== "undefined") {
      setIsWhatsAppHidden(data.isWhatsAppHidden);
    }
  }, [data]);

  const handleWhatsAppSwitchToggle = async (event, checked) => {
    setIsWhatsAppHidden(checked);
    try {
      await hideWhatsAppMutation({ isWhatsAppHidden: checked }).unwrap();
      Notification("WhatsApp privacy setting updated", "success");
    } catch (err) {
      Notification("Error updating WhatsApp privacy setting", "error");
    }
  };

  const validatePhoneNumber = (number) => {
    try {
      const phoneNumber = `${countryCode}${number}`;
      return isValidPhoneNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    setUser({ whatsapp: data?.whatsapp });
  }, [data]);

  useEffect(() => {
    if (updateData?.status) {
      setModal(false);
      Notification(updateData.message, "success");
    }
    if (error) {
      Notification(error?.data?.message, "error");
    }
  }, [updateData, error, setModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCountryPicker(false);
      }
    };

    if (showCountryPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCountryPicker]);

  const filteredCountries = Country.getAllCountries().filter(
    (country) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      country.phonecode.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return <Pending />;
  }

  return (
    modal && (
      <>
        <div
          className="whatsapp-modal-backdrop_effect"
          onClick={() => setModal(false)}
        />
        <div className="whatsapp-modal-container">
          <div className="whatsapp-modal-content">
            <div className="whatsapp-modal-handle"></div>

            <div className="whatsapp-modal-header">
              <h2>{title}</h2>
              <button
                className="whatsapp-modal-close-button"
                onClick={() => setModal(false)}
              >
                <IoClose size={25} />
              </button>
            </div>
            <div
              style={{
                width: "100%",
                marginBottom: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  marginLeft: "1rem",
                }}
              >
                Hide WhatsApp from sponsor
              </span>
              <Switch
                id="hideWhatsAppSwitch"
                checked={isWhatsAppHidden}
                onChange={handleWhatsAppSwitchToggle}
              />
            </div>
            <p
              style={{
                fontSize: "14px",
                color: isWhatsAppHidden ? "green" : "red",
                text: "right",
                marginTop: "0.5rem",
              }}
            >
              {isWhatsAppHidden
                ? "WhatsApp hidden from sponsor"
                : "WhatsApp visible to sponsor"}
            </p>
            <div className="whatsapp-modal-body">
              <div
                className="whatsapp-modal-country-picker"
                onClick={() => setShowCountryPicker(!showCountryPicker)}
              >
                <span>
                  {selectedFlag} {countryCode}
                </span>
                <IoChevronDown size={20} />
              </div>

              {showCountryPicker && (
                <div
                  className="whatsapp-modal-country-dropdown"
                  ref={dropdownRef}
                >
                  <div className="whatsapp-modal-search">
                    <input
                      type="text"
                      placeholder="Search country..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  {filteredCountries.map((country) => (
                    <div
                      key={country.isoCode}
                      className="whatsapp-modal-country-option"
                      onClick={() => {
                        setCountryCode(
                          country.phonecode.startsWith("+")
                            ? country.phonecode
                            : `+${country.phonecode}`
                        );
                        setSelectedFlag(country.flag || "🏳️");
                        setShowCountryPicker(false);
                        setSearchTerm("");
                      }}
                    >
                      <span>{country.flag || "🏳️"}</span>
                      <span>{country.name}</span>
                      <span>
                        {country.phonecode.startsWith("+")
                          ? country.phonecode
                          : `+${country.phonecode}`}
                      </span>
                    </div>
                  ))}
                </div>
              )}

              <div className="whatsapp-modal-input-container">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className={`whatsapp-icon ${isFocus ? "focused" : ""} ${
                    !isValid ? "invalid" : ""
                  }`}
                />
                <input
                  type="text"
                  placeholder="WhatsApp Number"
                  onFocus={() => setIsFocus(true)}
                  onBlur={() => setIsFocus(false)}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setUser({ whatsapp: value });
                    setIsValid(validatePhoneNumber(value));
                  }}
                  value={user?.whatsapp || ""}
                  className={!isValid ? "invalid" : ""}
                />
                {!isValid && (
                  <span className="error-message">
                    Invalid phone number for selected country
                  </span>
                )}
              </div>

              <button
                className="whatsapp-modal-submit-button"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default WhatsAppModal;
