import React from "react";
import "./_pending.scss";

const Pending = () => {
  return (
    <div className="pending_log">
      <img
        className="skeleton"
        src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706605752/TaskPlanet/logo_bak4ht.png"
        alt="pending"
      ></img>
      <div className="loading">
        <div className="line-box">
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

export default Pending;
