import React, { useState, useEffect, useCallback, useRef } from "react";
import { Pencil } from "lucide-react";
import PointIcon from "../../../assets/images/Point.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./buyReferral.css";
import { useNavigate } from "react-router-dom";
import {
  useGetReferralCostQuery,
  useBuyReferralMutation,
} from "../../../services/userApi";
import { useGetTotalPointsQuery } from "../../../services/leaderBoardApi";
import { Notification } from "../../../components/ToastNotification";

const BuyReferrals = ({ isVisible, onClose, referralQuantity = 1 }) => {
  const [isEditingQuantity, setIsEditingQuantity] = useState(false);
  const [currentQuantity, setCurrentQuantity] = useState(referralQuantity);
  const [isDragging, setIsDragging] = useState(false);
  const country = "india";

  // Add refs for timers to implement press and hold
  const incrementTimerRef = useRef(null);
  const decrementTimerRef = useRef(null);

  const { data: referralCostData } = useGetReferralCostQuery();
  const referralCost = referralCostData?.eachReferralCost;
  //console.log("cost",referralCost);

  const [buyReferral, { isLoading }] = useBuyReferralMutation();
  const { data: totalPointsData } = useGetTotalPointsQuery();
  const currentUserPoints = totalPointsData?.currentPoint || 0;

  // Function to recursively increment with setTimeout
  const incrementValue = () => {
    // First increment immediately
    setCurrentQuantity((prev) => Math.min(1000, prev + 1));

    // Set a timeout to start continuous increment after a delay
    incrementTimerRef.current = setTimeout(() => {
      // Start recursive function for continuous updates
      const continuousIncrement = () => {
        setCurrentQuantity((prev) => Math.min(1000, prev + 1));
        incrementTimerRef.current = setTimeout(continuousIncrement, 150);
      };
      continuousIncrement();
    }, 1000);
  };

  // Function to stop the increment timer
  const stopIncrementTimer = () => {
    clearTimeout(incrementTimerRef.current);
    incrementTimerRef.current = null;
  };

  // Function to recursively decrement with setTimeout
  const decrementValue = () => {
    // First decrement immediately
    setCurrentQuantity((prev) => Math.max(1, prev - 1));

    // Set a timeout to start continuous decrement after a delay
    decrementTimerRef.current = setTimeout(() => {
      // Start recursive function for continuous updates
      const continuousDecrement = () => {
        setCurrentQuantity((prev) => Math.max(1, prev - 1));
        decrementTimerRef.current = setTimeout(continuousDecrement, 150);
      };
      continuousDecrement();
    }, 1000);
  };

  // Function to stop the decrement timer
  const stopDecrementTimer = () => {
    clearTimeout(decrementTimerRef.current);
    decrementTimerRef.current = null;
  };

  // Cleanup timers when component unmounts
  useEffect(() => {
    return () => {
      if (incrementTimerRef.current) clearTimeout(incrementTimerRef.current);
      if (decrementTimerRef.current) clearTimeout(decrementTimerRef.current);
    };
  }, []);

  // Update local state immediately while sliding
  const handleSliderChange = (value) => {
    setCurrentQuantity(value);
  };

  // Handle the start of sliding
  const handleSliderDragStart = () => {
    setIsDragging(true);
  };
  const totalCharges = currentQuantity * referralCost;
  const userEligible = currentUserPoints >= totalCharges;
  const navigate = useNavigate();

  const onSubmit = async () => {
    console.log("currentQuantity", currentQuantity);
    console.log("totalCharges", totalCharges);
    try {
      const response = await buyReferral({
        count: currentQuantity,
        pointsDebit: totalCharges,
      });

      console.log("response", response);

      if (response?.error) {
        console.log(response?.error);
      } else {
        Notification("Buy Referral order placed successfully", "success");
        onClose();
        navigate("/home/referral-purchase-history", {
          state: {
            openFirstCard: true,
          },
        });
      }
    } catch (error) {
      Notification("Error while placing buy Referral order ", "error");
      console.error("Error purchasing referral:", error);
    }
  };

  useEffect(() => {
    setCurrentQuantity(currentQuantity);
  }, [currentQuantity]);

  // console.log("quantity", currentQuantity);
  // console.log("each cost", referralCost);
  // console.log("charges",totalCharges);
  if (!isVisible) return null;

  return (
    <div className="promote-modal-overlay">
      <div className="promote-modal-container">
        <div className="promote-modal-content">
          <div className="promote-modal-header-section">
            <h2 className="promote-modal-header">Referral Buy Summary</h2>
            <div
              className={`promote-status-badge ${
                userEligible ? "promote-eligible" : "promote-ineligible"
              }`}
            >
              <span className="promote-status-text">
                {userEligible ? "Eligible" : "Insufficient Balance"}
              </span>
            </div>
          </div>

          <div className="promote-modal-subheader-section">
            <div className="promote-modal-subheader-container">
              <h3 className="promote-modal-subheader">
                Get {currentQuantity} Referral
              </h3>
            </div>
          </div>

          <div className="promote-info-item">
            <span className="promote-info-label">Per Referral Charge:</span>
            <div className="promote-value-container">
              <span className="promote-value-text">{referralCost}</span>
              <img
                src={PointIcon}
                alt="Points"
                className="promote-point-icon"
              />
            </div>
          </div>

          <div className="promote-info-item">
            <span className="promote-info-label">Quantity:</span>
            <div className="promote-value-container promote-editable-container">
              <span className="promote-value-text">
                {currentQuantity} Referral
              </span>
              <button
                className="promote-edit-button"
                onClick={() => setIsEditingQuantity(true)}
              >
                <Pencil size={16} color="#2563EB" />
              </button>
            </div>
          </div>

          {isEditingQuantity && (
            <div className="promote-slider-container">
              <Slider
                min={1}
                max={Math.max(1000)}
                value={currentQuantity}
                onChange={handleSliderChange}
              />
              <div className="promote-slider-controls-container">
                <button
                  className="promote-slider-control-button"
                  onMouseDown={decrementValue}
                  onMouseUp={stopDecrementTimer}
                  onMouseLeave={stopDecrementTimer}
                >
                  <span className="promote-slider-control-button-text">-</span>
                </button>

                <div className="promote-slider-labels">
                  <span className="promote-slider-value">1</span>
                  <span className="promote-slider-value">{Math.max(1000)}</span>
                </div>

                <button
                  className="promote-slider-control-button"
                  onMouseDown={incrementValue}
                  onMouseUp={stopIncrementTimer}
                  onMouseLeave={stopIncrementTimer}
                >
                  <span className="promote-slider-control-button-text">+</span>
                </button>
              </div>
              <div className="promote-slider-buttons-container">
                <button
                  className="promote-slider-button promote-reset-button"
                  onClick={() => {
                    setCurrentQuantity(1);
                  }}
                >
                  Reset
                </button>
                <button
                  className="promote-slider-button promote-done-button"
                  onClick={() => setIsEditingQuantity(false)}
                >
                  Done
                </button>
              </div>
            </div>
          )}

          <div className="promote-info-item">
            <span className="promote-info-label">Total Charges:</span>
            <div className="promote-value-container">
              <span className="promote-value-text">{totalCharges}</span>
              <img
                src={PointIcon}
                alt="Points"
                className="promote-point-icon"
              />
            </div>
          </div>

          <div className="promote-debit-notice">
            <div className="promote-debit-content">
              <span className="promote-debit-amount">{totalCharges}</span>
              <img
                src={PointIcon}
                alt="Points"
                className="promote-point-icon"
              />
              <span className="promote-debit-text">will be debited.</span>
            </div>
          </div>

          {!userEligible && (
            <div className="promote-required-points-notice">
              <div className="promote-required-points-content">
                <div className="promote-required-points-amount">
                  <div className="promote-amount-badge">
                    <div className="promote-amount-with-point">
                      <span className="promote-amount-value">
                        {totalCharges - currentUserPoints}
                      </span>
                      <img
                        src={PointIcon}
                        alt="Points"
                        className="promote-point-icon"
                      />
                      <span className="promote-required-text">required</span>
                    </div>
                  </div>
                  <div className="promote-currency-conversion">
                    <span className="promote-currency-text">
                      Please add {country === "India" ? "₹" : "$"}
                      {totalCharges - currentUserPoints} to your wallet
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="promote-modal-actions">
            {!userEligible ? (
              <>
                <button
                  onClick={() =>
                    navigate("/home/deposit-method", {
                      state: {
                        amount: (
                          (totalCharges - currentUserPoints) /
                          (country === "India" ? 100 : 9000)
                        ).toFixed(country === "India" ? 2 : 4),
                      },
                    })
                  }
                  className="promote-add-money-button"
                >
                  Add Money {country === "India" ? "₹" : "$"}
                </button>
                <button className="promote-cancel-button" onClick={onClose}>
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  className="promote-pay-button"
                  onClick={onSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="promote-loader-container">
                      <div className="promote-spinner" />
                      <span>Processing...</span>
                    </div>
                  ) : (
                    <>
                      <span className="promote-pay-button-text">
                        Pay From Wallet
                      </span>
                      <div className="promote-wallet-balance">
                        <span className="promote-balance-text">
                          {totalCharges}
                        </span>
                        <img
                          src={PointIcon}
                          alt="Points"
                          className="promote-point-icon"
                        />
                      </div>
                    </>
                  )}
                </button>
                <button
                  className="promote-cancel-button"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyReferrals;
